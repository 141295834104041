import React, { useState, useEffect } from "react";

import Header from "../components/header-component";
import Slider from "../components/home-slider-component";
import Promotion from "../components/home-promotions-component";
import PromotionComponent from "../components/promotion-component";
import MostPopularItem from "../components/home-most-popular-item";
import ViewedLastItems from "../components/home-viewed-last-items";
import Brand from "../components/home-brand";
import Footer from "../components/footer";
import { getBannerData } from "../services/apiServices";
import CollectionGroups from "../components/home-collection-groups";

export default function Home() {
  const [bannerState, setBannerState] = useState([]);
  const [ads1State, setAds1State] = useState([]);
  const [ads2State, setAds2State] = useState([]);

  // get home page banner data
  const bannerData = async (type) => {
    getBannerData(type).then((res) => {
      if (type === 5) {
        setAds1State(res.data);
      } else if (type === 6) {
        setAds2State(res.data);
      }
    });
  };

  useEffect(() => {
    bannerData(5);
    bannerData(6);
  }, []);

  return (
    <React.Fragment>
      {/* back to top start */}
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      {/* back to top end */}
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {/* offcanvas area start */}
      <div className="offcanvas__area">
        <div className="offcanvas__wrapper">
          <div className="offcanvas__close">
            <button className="offcanvas__close-btn" id="offcanvas__close-btn">
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="offcanvas__content">
            <div className="offcanvas__logo mb-40">
              <a href="index.html">
                <img
                  src="assets/img/logo/logo2.png"
                  alt="logo"
                  style={{ width: "75%" }}
                />
              </a>
            </div>
            <div className="offcanvas__search mb-25">
              <form action="#">
                <input type="text" placeholder="What are you searching for?" />
                <button type="submit">
                  <i className="far fa-search"></i>
                </button>
              </form>
            </div>
            <div className="mobile-menu fix"></div>
            <div className="offcanvas__action"></div>
          </div>
        </div>
      </div>
      {/* offcanvas area end */}
      <div className="body-overlay"></div>
      {/* offcanvas area end */}
      <main>
        {/* Slider Component start */}
        <Slider />
        {/* Slider Component end */}

        {/* brnd Component start */}
        <Brand />
        {/* brand Component end */}

        <PromotionComponent />

        {/* promotions Component start */}
        <Promotion />
        {/* promotions Component end */}

        {/* collection group Component start */}
        <CollectionGroups />
        {/* collection group Component end */}

        {/* most popular items Component start */}
        <MostPopularItem />
        {/* most popular items Component end */}

        {/* home banner 1 area start */}
        {ads1State.length > 0 ? (
          <section className="trending-product-area light-bg-s pt-25 pb-15">
            <div className="container custom-conatiner">
              <div className="row">
                <div className="product-image w-img">
                  {ads1State[ads1State.length - 1].url.length ? (
                    <a href={ads1State[ads1State.length - 1].url}>
                      <img src={ads1State[0].image} alt="product" />
                    </a>
                  ) : (
                    <a>
                      <img src={ads1State[0].image} alt="product" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {/* home banner 1 area end */}

        {/* last items Component start */}
        <ViewedLastItems />
        {/* last items Component end */}

        {/* home banner 2 area start */}
        {ads2State.length > 0 ? (
          <section className="trending-product-area light-bg-s pt-25 pb-15">
            <div className="container custom-conatiner">
              <div className="row">
                <div className="product-image w-img">
                  {ads2State[ads2State.length - 1].url.length ? (
                    <a href={ads2State[ads2State.length - 1].url}>
                      <img
                        src={ads2State[ads2State.length - 1].image}
                        alt="product"
                      />
                    </a>
                  ) : (
                    <a>
                      <img
                        src={ads2State[ads2State.length - 1].image}
                        alt="product"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {/* home banner 2 area end */}
      </main>
      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}

// export default class Home extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             bannerState: [],
//             isLoading: true
//         };
//     }

//     componentDidMount() {
//         this.bannerData();
//     }

//     // get home page banner data
//     bannerData = async () => {
//         const res = await axios
//             .get(
//                 UserService.api_Url + "api/app/banner/list/1",
//                 { headers: UserService.header }
//             ).catch(err=>{
//                 console.log(err);
//               });
//         this.setState({
//             bannerState: res.data
//         });

//     };

//     render() {
//         return (
//             <React.Fragment>
//                 {/* back to top start */}
//                 <div className="progress-wrap">
//                     <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
//                         <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
//                     </svg>
//                 </div>
//                 {/* back to top end */}
//                 {/* Header Component start */}
//                 <Header />
//                 {/* Header Component end */}

//                 {/* offcanvas area start */}
//                 <div className="offcanvas__area">
//                     <div className="offcanvas__wrapper">
//                     <div className="offcanvas__close">
//                         <button className="offcanvas__close-btn" id="offcanvas__close-btn">
//                             <i className="fal fa-times"></i>
//                         </button>
//                     </div>
//                     <div className="offcanvas__content">
//                         <div className="offcanvas__logo mb-40">
//                             <a href="index.html">
//                             <img src="assets/img/logo/logo-white.png" alt="logo" />
//                             </a>
//                         </div>
//                         <div className="offcanvas__search mb-25">
//                             <form action="#">
//                                 <input type="text" placeholder="What are you searching for?" />
//                                 <button type="submit" ><i className="far fa-search"></i></button>
//                             </form>
//                         </div>
//                         <div className="mobile-menu fix"></div>
//                         <div className="offcanvas__action">

//                         </div>
//                     </div>
//                     </div>
//                 </div>
//                 {/* offcanvas area end */}
//                 <div className="body-overlay"></div>
//                 {/* offcanvas area end */}
//                 <main>
//                     {/* Slider Component start */}
//                     <Slider />
//                     {/* Slider Component end */}

//                     {/* promotions Component start */}
//                     <Promotion/>
//                     {/* promotions Component end */}

//                     {/* most popular items Component start */}
//                     <MostPopularItem/>
//                     {/* most popular items Component end */}

//                     {/* home banner 1 area start */}
//                     {bannerState.length > 0 ?
//                         <section className="trending-product-area light-bg-s pt-25 pb-15">
//                             <div className="container custom-conatiner">
//                                 <div className="row">
//                                     <div className="product-image w-img">
//                                         <a href="#.html">
//                                             <img src={bannerState[0].image} alt="product"/>
//                                         </a>
//                                     </div>
//                                 </div>
//                             </div>
//                         </section>
//                         :""
//                     }

//                     {/* home banner 1 area end */}

//                     {/* last items Component start */}
//                     <ViewedLastItems/>
//                     {/* last items Component end */}

//                     {/* home banner 2 area start */}
//                     {bannerState.length > 0 && bannerState.length >= 2 ?
//                         <section className="trending-product-area light-bg-s pt-25 pb-15">
//                             <div className="container custom-conatiner">
//                                 <div className="row">
//                                     <div className="product-image w-img">
//                                         <a href="#.html">
//                                             <img src={bannerState[1].image} alt="product" />
//                                         </a>
//                                     </div>
//                                 </div>
//                             </div>
//                         </section>
//                         :""
//                     }
//                     {/* home banner 2 area end */}

//                 </main>
//                 {/* Footer Component start */}
//                 <Footer />
//                 {/* Footer Component end */}
//             </React.Fragment>
//         );
//     }
// }
