import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import XMLParser from "react-xml-parser";

import Header from "../components/header-component";
import Footer from "../components/footer";

import "../resources/css/related-products.css";
import useCartStore from "../common/globalStore";

import {
  getAssociateProduct,
  addToCartPOST,
  getNextVarAttrData,
  getProductData,
  getGiftItemByProduct,
  getProdcutAvailability,
  checkTp2Availability,
  getGroupProductList,
} from "../services/apiServices";
import swal from "sweetalert2";
import $ from "jquery";

export default function singleProduct() {
  const location = useLocation();
  const { sku } = useParams();
  let navigate = useNavigate();
  let productId = 0;
  let modelId = 0;

  const [productState, setProductState] = useState([]);
  const [productImageState, setProductImageState] = useState([]);
  const [keyAttrState, setKeyAttrState] = useState([]);
  const [variantsState, setVariantsState] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [assoItemAvailability, setAssoItemAvailability] = useState(false);
  const [mGrpProducts, setMGrpProducts] = useState([]);
  const [mProducts, setMProducts] = useState([]);
  const [opGrpProducts, setOpGrpProducts] = useState([]);
  const [opProducts, setOpProducts] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const getCartData = useCartStore((state) => state.updateCart);
  const [factList, setFactList] = useState([]);
  const [giftState, setGiftState] = useState([]);
  const [prodcutAvailabilityState, setProdcutAvailabilityState] = useState([]);
  const [paQuantity, setPaQuantity] = useState(1);
  const [paResExDate, setResPaExDate] = useState("");
  const [paResQuantity, setResPaQuantity] = useState("");
  const [groupProListState, setGroupProListState] = useState([]);

  //drop down
  const [variantDDL1, setVariantDDL1] = useState([]);
  const [variantDDL2, setVariantDDL2] = useState([]);
  const [variantDDL3, setVariantDDL3] = useState([]);
  const [variantDDL4, setVariantDDL4] = useState([]);
  const [variantDDL5, setVariantDDL5] = useState([]);

  // set DDL Labels
  let { labelDDL1, labelDDL2, labelDDL3, labelDDL4, labelDDL5 } = "";

  if (typeof variantsState != "undefined" && variantsState.length > 0) {
    variantsState.map((el, index) => {
      switch (index) {
        case 0:
          labelDDL1 = el.name;
          break;
        case 1:
          labelDDL2 = el.name;
          break;
        case 2:
          labelDDL3 = el.name;
          break;
        case 3:
          labelDDL4 = el.name;
          break;
        case 4:
          labelDDL5 = el.name;
          break;

        default:
          break;
      }
    });
  }

  // get product data
  const productData = async (sku, proId, modId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const customerId = user.customerId;
    const categoryId = 0;

    getProductData(sku, proId, modId, categoryId, customerId)
      .then((res) => {
        const product = res.data;
        const vdata = product.variants;

        if (product.length != 0) {
          productId = product.productId;
          setProductState(product);
          setProductImageState(product.images != null ? product.images : []);
          setKeyAttrState(product.keySpecs != null ? product.keySpecs : []);
          setFactList(product.facts.length > 0 ? product.facts : []);
          if (product.modelId === 0) {
            setVariantsState(product.variants != null ? product.variants : []);
          } else {
            modelId = product.modelId;
          }
          setSpecs(product.specs != null ? product.specs : []);

          if (product.modelId === 0 && vdata.length > 0) {
            vdata.map((el, index) => {
              switch (index) {
                case 0:
                  setVariantDDL1(el.attrs);
                  break;
                case 1:
                  setVariantDDL2(el.attrs);
                  break;
                case 2:
                  setVariantDDL3(el.attrs);
                  break;
                case 3:
                  setVariantDDL4(el.attrs);
                  break;
                case 4:
                  setVariantDDL5(el.attrs);
                  break;
                default:
                  break;
              }
            });
          }

          associateProduct(productId, modelId);
          gift(productId);
          groupProductList(productId);
          prodcutAvailability(productId, modelId);
        } else {
          navigate("/page-not-found");
        }

        setLoading(true);
        $("select").niceSelect();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get associate product data
  const associateProduct = async (productId, modelId) => {
    getAssociateProduct(productId, modelId)
      .then((res) => {
        const items = res.data;
        const mpro = items.mandatoryProducts;
        setMGrpProducts(items.mandatoryGroupProducts);
        setMProducts(items.mandatoryProducts);
        setOpGrpProducts(items.optionalGroupProducts);
        setOpProducts(items.optionalProducts);
        if (
          items.mandatoryGroupProducts.length > 0 ||
          items.mandatoryProducts.length > 0 ||
          items.optionalGroupProducts.length > 0 ||
          items.optionalProducts.length > 0
        ) {
          setAssoItemAvailability(true);
          mpro.forEach((el) => {
            const rowObj = [
              {
                productId: el.prodcutId,
                pname: el.prodcutName,
                quantity: el.quantity,
                sku: el.sku,
                price: el.specialPrice > 0 ? el.specialPrice : el.price,
              },
            ];
            setCartList(rowObj);
          });
        }
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  };

  // get next variant attribut
  const getNextVarAttr = (variantLevel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const customerId = user.customerId;
    const productId = productState.productId;
    const attrIds = [];
    const noOfVarants = variantsState.length;

    //get all slected value in variant drop down
    const allVarClass = Array.from(
      document.querySelectorAll("select.variant_picker")
    );
    //looping selected value and create integer array
    allVarClass.forEach((element) => {
      var options = element.options;
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          attrIds.push(Number(options[i].value));
        }
      }
    });

    getNextVarAttrData(
      customerId,
      productId,
      variantLevel,
      noOfVarants,
      attrIds
    )
      .then((res) => {
        const index = variantLevel + 1;
        const product = res.data;

        if (product.type === 0) {
          //next attr
          switch (index) {
            case 2:
              setVariantDDL2(product.attributes);
              break;
            case 3:
              setVariantDDL3(product.attributes);
              break;
            case 4:
              setVariantDDL4(product.attributes);
              break;
            case 5:
              setVariantDDL5(product.attributes);
              break;

            default:
              break;
          }
        } else if (product.type === 1) {
          //product
          productData(null, product.product.productId, product.product.modelId);
        } else if (product.type === 2) {
          //error
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function qtyIncrement() {
    setQuantity(function (prevQty) {
      return (prevQty += 1);
    });
  }

  function qtyDecrement() {
    setQuantity(function (prevQty) {
      if (prevQty > 0) {
        return (prevQty -= 1);
      } else {
        return (prevQty = 0);
      }
    });
  }

  function paQtyIncrement() {
    setPaQuantity(function (paPrevQty) {
      if (paPrevQty < 99) {
        return (paPrevQty += 1);
      } else {
        return (paPrevQty = 99);
      }
    });
  }

  function paQtyDecrement() {
    setPaQuantity(function (paPrevQty) {
      if (paPrevQty > 0) {
        return (paPrevQty -= 1);
      } else {
        return (paPrevQty = 1);
      }
    });
  }

  function paOnchangeEvent(e) {
    let { value, min, max } = e.target;
    value = Math.max(
      Number(min),
      Math.min(Number(max), Number(value.replace(/\D/, 1)))
    );
    setPaQuantity(value);
  }

  function qtyOnchangeEvent(e) {
    let { value, min, max } = e.target;
    value = Math.max(
      Number(min),
      Math.min(Number(max), Number(value.replace(/\D/, 1)))
    );
    setQuantity(value);
  }

  //Add item to cart
  const addToCart = async () => {
    if (variantsState.length > 0 && productState.modelId == 0) {
      swal.fire({
        position: "top",
        icon: "error",
        title: "Error",
        text: "Please Select Variants!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      let price =
        productState.specialPrice > 0
          ? productState.specialPrice
          : productState.orginalPrice;
      addToCartPOST(
        user.customerId,
        productState.productId,
        productState.modelId,
        quantity,
        price,
        2,
        cartList
      )
        .then((res) => {
          getCartData();
          swal.fire({
            position: "top",
            icon: "success",
            title: "Success",
            text: "A new item has been added to your Shopping Cart!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        });
    }
  };

  //check addons list product exists or not
  function addonExists(pId) {
    return cartList.some(function (el) {
      return el.productId === pId;
    });
  }

  // add associate items to addon list
  const addToAddonList = (el) => {
    const isExists = addonExists(el.prodcutId);

    if (isExists === false) {
      const rowObj = {
        productId: el.prodcutId,
        pname: el.prodcutName,
        quantity: el.quantity,
        sku: el.sku,
        price: el.specialPrice > 0 ? el.specialPrice : el.price,
      };

      setCartList((prev) => [...prev, rowObj]);
    } else {
    }
  };

  // get gift product data
  const gift = async (productId) => {
    getGiftItemByProduct(productId)
      .then((res) => {
        setGiftState(res.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  };

  //product group data
  const groupProductList = async (productId) => {
    getGroupProductList(productId)
      .then((res) => {
        setGroupProListState(res.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  };

  // get product availability data
  const prodcutAvailability = async (productId, modelId) => {
    getProdcutAvailability(productId, modelId)
      .then((res) => {
        setProdcutAvailabilityState(res.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  };

  //check availability
  const checkAvailability = async () => {
    let brand = productState.brandId;
    let sku = productState.sku;
    let ean = productState.ean;
    console.log(brand + " | " + sku + " | " + ean);

    checkTp2Availability(brand, sku, ean, paQuantity)
      .then((res) => {
        let jsonDataFromXml = new XMLParser().parseFromString(res.data);
        if (
          jsonDataFromXml.getElementsByTagName("MessageType")[0].value == "E"
        ) {
          setResPaExDate("");
          setResPaQuantity("");
        } else {
          let qty =
            "Available : " +
            jsonDataFromXml.getElementsByTagName("ConfirmedQuantity")[0].value;
          let esDate = jsonDataFromXml.getElementsByTagName("EstimatedDate")[0];
          const year = esDate.getElementsByTagName("Year")[0].value;
          const month = esDate.getElementsByTagName("Month")[0].value;
          const day = esDate.getElementsByTagName("Day")[0].value;

          let available = {
            availableDate: new Date(year + "-" + month + "-" + day), // Today
            increaseAvailableDate: 2, // Days to add
          };
          console.log(available.availableDate);

          const dayOfWeek = available.availableDate.getDay();
          if (dayOfWeek == 5) {
            available.increaseAvailableDate = 3;
          }

          let date = new Date(
            new Date(available.availableDate).setDate(
              available.availableDate.getDate() +
                available.increaseAvailableDate
            )
          );

          let dateMDY = `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`;

          setResPaExDate("Expected: " + dateMDY);
          setResPaQuantity(qty);
        }
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  };

  useEffect(() => {
    // get product data
    (async () => {
      await productData(sku);
      // await associateProduct(productId, modelId);
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      <main>
        {/* breadcrumb__area-start */}
        <section className="breadcrumb__area box-plr-75">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb__wrapper">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/home">Home</a>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Product
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                        style={{ color: "#999" }}
                      >
                        {productState.productName}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* breadcrumb__area-end */}

        {/* product details Component start */}
        <div className="product-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="product__details-nav d-sm-flex align-items-start">
                  <ul
                    className="nav nav-tabs flex-sm-column justify-content-between"
                    id="productThumbTab"
                    role="tablist"
                  >
                    {typeof productImageState != "undefined" &&
                    productImageState.length > 0 ? (
                      <React.Fragment>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="thumbOne-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#thumbOne"
                            type="button"
                            role="tab"
                            aria-controls="thumbOne"
                            aria-selected="true"
                          >
                            <img
                              className="sp-image"
                              src={productImageState[0].url}
                              alt=""
                            />
                          </button>
                        </li>
                        {productImageState.length > 1 ? (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="thumbTwo-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#thumbTwo"
                              type="button"
                              role="tab"
                              aria-controls="thumbTwo"
                              aria-selected="false"
                            >
                              <img
                                className="sp-image"
                                src={productImageState[1].url}
                                alt=""
                              />
                            </button>
                          </li>
                        ) : (
                          ""
                        )}
                        {productImageState.length > 2 ? (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="thumbThree-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#thumbThree"
                              type="button"
                              role="tab"
                              aria-controls="thumbThree"
                              aria-selected="false"
                            >
                              <img
                                className="sp-image"
                                src={productImageState[2].url}
                                alt=""
                              />
                            </button>
                          </li>
                        ) : (
                          ""
                        )}
                        {productImageState.length > 3 ? (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="thumbFour-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#thumbFour"
                              type="button"
                              role="tab"
                              aria-controls="thumbFour"
                              aria-selected="false"
                            >
                              <img
                                className="sp-image"
                                src={productImageState[3].url}
                                alt=""
                              />
                            </button>
                          </li>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ) : (
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="thumbOne-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#thumbOne"
                          type="button"
                          role="tab"
                          aria-controls="thumbOne"
                          aria-selected="true"
                        >
                          <img
                            className="sp-image"
                            src="assets/img/default/product.png"
                            alt=""
                          />
                        </button>
                      </li>
                    )}
                  </ul>
                  <div className="product__details-thumb">
                    <div className="tab-content" id="productThumbContent">
                      {typeof productImageState != "undefined" &&
                      productImageState.length > 0 ? (
                        <React.Fragment>
                          <div
                            className="tab-pane fade show active"
                            id="thumbOne"
                            role="tabpanel"
                            aria-labelledby="thumbOne-tab"
                          >
                            <div className="product__details-nav-thumb w-img">
                              <img src={productImageState[0].url} alt="" />
                            </div>
                          </div>
                          {productImageState.length > 1 ? (
                            <div
                              className="tab-pane fade"
                              id="thumbTwo"
                              role="tabpanel"
                              aria-labelledby="thumbTwo-tab"
                            >
                              <div className="product__details-nav-thumb w-img">
                                <img src={productImageState[1].url} alt="" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {productImageState.length > 2 ? (
                            <div
                              className="tab-pane fade"
                              id="thumbThree"
                              role="tabpanel"
                              aria-labelledby="thumbThree-tab"
                            >
                              <div className="product__details-nav-thumb w-img">
                                <img src={productImageState[2].url} alt="" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {productImageState.length > 3 ? (
                            <div
                              className="tab-pane fade"
                              id="thumbFour"
                              role="tabpanel"
                              aria-labelledby="thumbFour-tab"
                            >
                              <div className="product__details-nav-thumb w-img">
                                <img src={productImageState[3].url} alt="" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        <div
                          className="tab-pane fade show active"
                          id="thumbOne"
                          role="tabpanel"
                          aria-labelledby="thumbOne-tab"
                        >
                          <div className="product__details-nav-thumb w-img">
                            <img src="assets/img/default/product.png" alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="product__details-content">
                  <h6>{productState.productName}</h6>

                  <div className="price mb-10">
                    {productState.specialPrice > 0 ? (
                      <React.Fragment>
                        <span style={{ color: "red" }}>
                          <s>
                            {UserService.numberFormat(
                              productState.orginalPrice
                            )}
                          </s>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          {UserService.numberFormat(productState.specialPrice)}
                        </span>
                        <span className="vat">&nbsp;&nbsp;(exc VAT)</span>
                      </React.Fragment>
                    ) : productState.orginalPrice > 0 ? (
                      <React.Fragment>
                        <span>
                          {UserService.numberFormat(productState.orginalPrice)}
                        </span>
                        <span className="vat">&nbsp;&nbsp;(exc VAT)</span>
                      </React.Fragment>
                    ) : variantsState.length > 0 ? (
                      <p style={{ color: "red" }}>
                        Pleases select the variant to view prices.
                      </p>
                    ) : (
                      ""
                    )}
                    {giftState.length > 0 && giftState[0].type == 1 ? (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        <img
                          style={{ width: "20%" }}
                          src="../assets/img/promotion/cashback-icon2.png"
                          alt="cash-back"
                        />{" "}
                        Earn{" "}
                        {UserService.numberFormat(giftState[0].value)
                          .toString()
                          .slice(0, -3)}{" "}
                        Cashback with this purchase{" "}
                        {giftState[0].promotionExpiration}
                        {"."}
                      </p>
                    ) : giftState.length > 0 && giftState[0].type == 3 ? (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        <img
                          style={{ width: "20%" }}
                          src="../assets/img/promotion/loyalty-icon2.png"
                          alt="cash-back"
                        />{" "}
                        Earn {giftState[0].value} loyalty points with this
                        purchase {giftState[0].promotionExpiration}
                        {"."}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* product availability */}
                  {prodcutAvailabilityState.stock != 0 ||
                  prodcutAvailabilityState.purch != 0 ? (
                    <div className="features-des mb-20 mt-10">
                      <div className="keyAttContainer">
                        <ul>
                          <div className="keyAttribute">
                            <li className="keyname">Current Stock</li>

                            <li className="keyval">
                              {" "}
                              : {prodcutAvailabilityState.stock}
                            </li>
                            <li className="keyval"></li>
                            <li className="keyval"></li>
                          </div>
                          <div className="keyAttribute">
                            <li className="keyname">On the way</li>
                            <li className="keyval">
                              {" "}
                              : {prodcutAvailabilityState.purch}
                            </li>
                            <li className="keyval"></li>
                            <li className="keyval"></li>
                          </div>

                          <div className="keyAttribute">
                            <li className="keyname">Check with Supplier</li>
                          </div>
                          <div className="keyAttribute">
                            <li
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div className="text-input">
                                <input
                                  type="text"
                                  value={paQuantity}
                                  onChange={paOnchangeEvent}
                                  min={1}
                                  max={99}
                                  style={{
                                    width: "110px",
                                    padding: "0px",
                                    height: "35px",
                                    fontSize: "15px",
                                    padding: "4px 20px",
                                    border: "1px solid #ebebeb",
                                  }}
                                />
                                {/* <div
                                  className="dec qtybutton"
                                  style={{ zIndex: "2" }}
                                  onClick={() => paQtyDecrement()}
                                >
                                  -
                                </div> */}
                                {/* <div
                                  className="inc qtybutton"
                                  style={{ zIndex: "2" }}
                                  onClick={() => paQtyIncrement()}
                                >
                                  +
                                </div> */}
                              </div>
                              <a
                                className="cart-btn"
                                onClick={() => checkAvailability()}
                                style={{
                                  cursor: "pointer",
                                  height: "35px",
                                  fontSize: "15px",
                                  padding: "4px 20px",
                                }}
                              >
                                Go
                              </a>
                              <span
                                style={{
                                  marginRight: "40px",
                                  marginLeft: "7px",
                                  marginTop: "7px",
                                }}
                              >
                                {paResQuantity}
                              </span>
                              <span style={{ marginTop: "7px" }}>
                                {paResExDate}
                              </span>
                            </li>
                            {/* <li
                              className="keyname"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "7px",
                              }}
                            >
                              <span style={{ marginRight: "40px" }}>
                                {paResQuantity}
                              </span>
                              <span>{paResExDate}</span>
                            </li> */}
                          </div>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Key Attributes start */}
                  <div className="features-des mb-20 mt-10">
                    <div className="keyAttContainer">
                      <ul>
                        {typeof keyAttrState != "undefined" &&
                          keyAttrState.map((attr, idx) => {
                            return (
                              <div key={idx} className="keyAttribute">
                                <li className="keyname">{attr.name}</li>
                                <li className="keyval"> : {attr.value}</li>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  {/* Key Attributes end */}
                  {/* variants start */}
                  {/* DD1 */}
                  {variantsState.length > 0 ? (
                    <div className="cart-option mb-15">
                      <label
                        htmlFor="select-choice-1"
                        className="variantOpLable"
                      >
                        {labelDDL1}
                        <span className="variantOpLableSpan">:</span>
                      </label>
                      <select
                        id={"attr_val_1"}
                        data-variant={1}
                        className={"nice-select variant_picker attr_val_1"}
                        style={{ float: "left", minWidth: "70%" }}
                        onChange={(e) => getNextVarAttr(1)}
                      >
                        <option value={0}>-- Select --</option>
                        {variantDDL1.map((attr) => {
                          return (
                            <option key={attr.attrId} value={attr.attrId}>
                              {attr.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* DDL 2 */}
                  {variantsState.length > 1 ? (
                    <div className="cart-option mb-15">
                      <label
                        htmlFor="select-choice-1"
                        className="variantOpLable"
                      >
                        {labelDDL2}
                        <span className="variantOpLableSpan">:</span>
                      </label>
                      <select
                        defaultValue={0}
                        id={"attr_val_1"}
                        data-variant={1}
                        className={"nice-select variant_picker attr_val_1"}
                        style={{ float: "left", minWidth: "70%" }}
                        onChange={(e) => getNextVarAttr(2)}
                      >
                        <option value={0}>-- Select --</option>
                        {variantDDL2.map((attr) => {
                          return (
                            <option key={attr.attrId} value={attr.attrId}>
                              {attr.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* DDL 3 */}
                  {variantsState.length > 2 ? (
                    <div className="cart-option mb-15">
                      <label
                        htmlFor="select-choice-1"
                        className="variantOpLable"
                      >
                        {labelDDL3}
                        <span className="variantOpLableSpan">:</span>
                      </label>
                      <select
                        id={"attr_val_1"}
                        data-variant={1}
                        className={"nice-select variant_picker attr_val_1"}
                        style={{ float: "left", minWidth: "70%" }}
                        onChange={(e) => getNextVarAttr(3)}
                      >
                        <option value={0}>-- Select --</option>
                        {variantDDL3.map((attr) => {
                          return (
                            <option key={attr.attrId} value={attr.attrId}>
                              {attr.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* DDL 4 */}
                  {variantsState.length > 3 ? (
                    <div className="cart-option mb-15">
                      <label
                        htmlFor="select-choice-1"
                        className="variantOpLable"
                      >
                        {labelDDL4}
                        <span className="variantOpLableSpan">:</span>
                      </label>
                      <select
                        id={"attr_val_1"}
                        data-variant={1}
                        className={"nice-select variant_picker attr_val_1"}
                        style={{ float: "left", minWidth: "70%" }}
                        onChange={(e) => getNextVarAttr(4)}
                      >
                        <option value={0}>-- Select --</option>
                        {variantDDL4.map((attr) => {
                          return (
                            <option key={attr.attrId} value={attr.attrId}>
                              {attr.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* DDL 5 */}
                  {variantsState.length > 4 ? (
                    <div className="cart-option mb-15">
                      <label
                        htmlFor="select-choice-1"
                        className="variantOpLable"
                      >
                        {labelDDL5}
                        <span className="variantOpLableSpan">:</span>
                      </label>
                      <select
                        id={"attr_val_1"}
                        data-variant={1}
                        className={"nice-select variant_picker attr_val_1"}
                        style={{ float: "left", minWidth: "70%" }}
                        onChange={(e) => getNextVarAttr(5)}
                      >
                        <option value={0}>-- Select --</option>
                        {variantDDL5.map((attr) => {
                          return (
                            <option key={attr.attrId} value={attr.attrId}>
                              {attr.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* variants end */}
                  {/* <div className="product-stock mb-20">
                    <h5>
                      Availability: <span> 10 in stock</span>
                    </h5>
                  </div> */}
                  {productState.tempStop == true ? (
                    <span className="status_font">Temporary not available</span>
                  ) : (
                    ""
                  )}

                  <div className="cart-option mb-15">
                    {productState.tempStop == false ? (
                      <React.Fragment>
                        <div className="product-quantity mr-20">
                          <div className="cart-plus-minus p-relative">
                            <input
                              type="text"
                              value={quantity}
                              onChange={qtyOnchangeEvent}
                              min={1}
                              max={9999}
                            />
                            <div
                              className="dec qtybutton"
                              style={{ zIndex: "2" }}
                              onClick={() => qtyDecrement()}
                            >
                              -
                            </div>
                            <div
                              className="inc qtybutton"
                              style={{ zIndex: "2" }}
                              onClick={() => qtyIncrement()}
                            >
                              +
                            </div>
                          </div>
                        </div>

                        <a
                          className="cart-btn"
                          onClick={() => addToCart()}
                          style={{ cursor: "pointer" }}
                        >
                          Add to Cart
                        </a>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                {assoItemAvailability ? (
                  <React.Fragment>
                    <div className="cart-option mb-15 add-item-css">
                      <div className="gap">
                        <h5>
                          Add Items :<span></span>
                        </h5>
                      </div>
                    </div>
                    <div className="gap">
                      <div className="cart-option mb-15">
                        {cartList.length > 0
                          ? cartList.map((el, idx) => {
                              return (
                                <div key={idx} class="col-md-3">
                                  <a>
                                    <span
                                      className="choose-handfrees"
                                      style={{ padding: "10px 10px" }}
                                    >
                                      {el.quantity} X {el.sku}:
                                      {UserService.numberFormat(el.price)}
                                    </span>
                                  </a>
                                  &nbsp; &nbsp;
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                {/* <div className="details-meta">
                  <div className="d-meta-left">
                    <div className="dm-item mr-20">
                      <a href="#">
                        <i className="fal fa-heart"></i>Add to wishlist
                      </a>
                    </div>
                    <div className="dm-item">
                      <a href="#">
                        <i className="fal fa-layer-group"></i>Compare
                      </a>
                    </div>
                  </div>
                  <div className="d-meta-left">
                    <div className="dm-item">
                      <a href="#">
                        <i className="fal fa-share-alt"></i>Share
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="product-tag-area mt-15">
                  <div className="product_info">
                    <span className="sku_wrapper">
                      <span className="title">SKU</span>
                      <span className="sku"> : {productState.sku}</span>
                    </span>
                    <span className="sku_wrapper">
                      <span className="title">EAN</span>
                      <span className="sku">
                        {" "}
                        : {productState.ean == null ? " N/A" : productState.ean}
                      </span>
                    </span>
                    <span className="posted_in">
                      <span className="title">Categories:</span>
                      <span className="sku"> : {productState.category}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* product details Component end */}

        {factList.length > 0 ? (
          <React.Fragment>
            <div className="product-details">
              <div
                className="container"
                style={{ paddingLeft: "0", marginTop: "20px" }}
              >
                <div className="row">
                  <div className="col-md-12 mb-12">
                    <div>
                      <h2 style={{ fontSize: "1.6rem", fontWeight: "600" }}>
                        Product Facts
                      </h2>
                    </div>
                    <div>
                      <div className="col-xl-12">
                        <ul style={{ marginLeft: "15px" }}>
                          {factList.map((e, idx) => {
                            return (
                              <li
                                key={idx}
                                style={{
                                  fontSize: "14px",
                                  listStyleType: "square",
                                }}
                              >
                                {e["content"]}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <hr className="product-divider" />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}

        {/* gift component start*/}

        {giftState.length > 0 &&
        (giftState[0].type == 2 || giftState[0].type == 4) ? (
          <React.Fragment>
            <div className="product-details">
              <div
                className="container "
                style={{
                  paddingLeft: "0",
                  marginTop: "20px",
                }}
              >
                <div className="row">
                  <div className="col-md-12 mb-12">
                    <div>
                      <h4 style={{ color: "red" }}>Gift Promotion</h4>
                    </div>
                    <div>
                      {giftState.map((pi, idx) => {
                        return (
                          <div
                            key={pi.productId}
                            className="col-sm-6 col-md-3 col-lg-2 col-xl-2 col-xxl-1"
                          >
                            <div
                              className="product__item product__item-2 b-radius-2 mb-20"
                              style={{ backgroundColor: "#f4f5f7" }}
                            >
                              <div className="product__thumb fix">
                                <div className="product-image w-img">
                                  {/* <Link to={"/product/" + pi.sku}> */}
                                  <img src={pi.defaultImage} alt="product" />
                                  {/* </Link> */}
                                </div>
                                <div className="product__offer"></div>
                                <div className="product-action product-action-2"></div>
                              </div>
                              <div className="product__content product__content-2">
                                <h6>
                                  {/* <Link to={"/product/" + pi.sku}> */}
                                  <a>
                                    {" "}
                                    {pi.productName}
                                    {pi.variantCount > 0 ? " (Variants) " : ""}
                                  </a>
                                  {/* </Link> */}
                                </h6>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <hr className="product-divider" />
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        {/* gift component end*/}

        {/* product group start*/}
        {groupProListState.length > 0 ? (
          <React.Fragment>
            <div className="product-details">
              <div
                className="container "
                style={{
                  paddingLeft: "0",
                  marginTop: "20px",
                }}
              >
                <div className="row">
                  <div className="col-md-12 mb-12">
                    <div>
                      <h4>Collection containing following products</h4>
                    </div>
                    <div style={{ display: "flex" }}>
                      {groupProListState.map((gp) => {
                        return (
                          <div
                            key={gp.productId + "@" + gp.modelId}
                            className="col-sm-6 col-md-3 col-lg-2 col-xl-2 col-xxl-1"
                            style={{ paddingRight: "10px" }}
                          >
                            <div
                              className="product__item product__item-2 b-radius-2 mb-20"
                              style={{ backgroundColor: "#f4f5f7" }}
                            >
                              <div className="product__thumb fix">
                                <div className="product-image w-img">
                                  <Link to={"/product/" + gp.sku}>
                                    <img src={gp.defaultImage} alt="product" />
                                  </Link>
                                </div>
                                <div className="product__offer"></div>
                                <div className="product-action product-action-2"></div>
                              </div>
                              <div className="product__content product__content-2">
                                <h6>
                                  <Link to={"/product/" + gp.sku}>
                                    <a>
                                      {" "}
                                      {gp.productName}
                                      {gp.variantCount > 0
                                        ? " (Variants) "
                                        : ""}
                                    </a>
                                  </Link>
                                </h6>
                                <div className="price">
                                  {gp.orginalPrice != 0 ? (
                                    <span>
                                      {" "}
                                      {gp.quantity} X{" "}
                                      {UserService.numberFormat(
                                        gp.orginalPrice
                                      )}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <hr className="product-divider" />
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        {/* product group end*/}

        {/* product details des Component start */}
        <div
          className="product-details"
          style={{ paddingBottom: "10px", marginTop: "10px" }}
        >
          <div className="container" style={{ paddingLeft: "0" }}>
            <div className="row">
              {/* Associate Product start */}
              {assoItemAvailability ? (
                <React.Fragment>
                  <div
                    className="product-stock mb-20"
                    style={{ paddingTop: "10px" }}
                  >
                    <h4>Related Products</h4>
                  </div>

                  <div className="col-xl-6" style={{ display: "flex" }}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="widget">
                          <div className="sidebar-title">
                            <div className="cart-option mb-15">
                              <a href="#">
                                <span
                                  className="choose-rp"
                                  style={{ padding: "5px 5px" }}
                                >
                                  Mandatory
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="n-sidebar-feed">
                            <ul>
                              {mProducts.map((el) => {
                                return (
                                  <li key={el.prodcutId}>
                                    <div className="feed-number">
                                      <a>
                                        <img src={el.defaultImage} alt="" />
                                      </a>
                                    </div>
                                    <div className="feed-content">
                                      <h4>
                                        <a>{el.prodcutName}</a>
                                      </h4>
                                      <div id="text_1">
                                        <a>{el.sku}</a>
                                      </div>
                                      <div id="text_1">
                                        <a>Quantity : 1</a>
                                      </div>
                                      <div id="text_1">
                                        {el.specialPrice > 0 ? (
                                          <React.Fragment>
                                            <a>
                                              <span style={{ color: "red" }}>
                                                <s>
                                                  {UserService.numberFormat(
                                                    el.price
                                                  )}
                                                </s>
                                              </span>
                                              &nbsp;&nbsp;
                                              <span>
                                                {UserService.numberFormat(
                                                  el.specialPrice
                                                )}
                                              </span>
                                              <span className="vat">
                                                (exc VAT)
                                              </span>
                                            </a>
                                          </React.Fragment>
                                        ) : el.price > 0 ? (
                                          <React.Fragment>
                                            <span>
                                              {UserService.numberFormat(
                                                el.price
                                              )}
                                            </span>
                                            <span className="vat">
                                              (exc VAT)
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        className=" paddons-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center_1 .w-100 "
                                        onClick={() => addToAddonList(el)}
                                        disabled
                                      >
                                        Adons
                                      </button>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* mandatory product group start */}
                      {mGrpProducts.map((el, idx) => {
                        return (
                          <div key={idx} className="col-xl-6">
                            <div className="widget">
                              <div className="sidebar-title">
                                <h4> {el.groupName} </h4>
                                <p>
                                  {el.quantity} Products Required. <br />
                                  Muti Maping{" "}
                                  {el.product[0].multiMapping === true
                                    ? "Enable"
                                    : "Disable"}
                                  .
                                </p>
                                <div className="cart-option mb-15">
                                  <a>
                                    <span
                                      className="choose-rp"
                                      style={{ padding: "5px 5px" }}
                                    >
                                      Mandatory
                                    </span>
                                  </a>
                                </div>
                              </div>
                              <div className="n-sidebar-feed">
                                <ul>
                                  {el.product.map((p, idx) => {
                                    return (
                                      <li key={idx}>
                                        <div className="feed-number">
                                          <a>
                                            <img src={p.defaultImage} alt="" />
                                          </a>
                                        </div>
                                        <div className="feed-content">
                                          <h4>
                                            <a>{p.prodcutName}</a>
                                          </h4>
                                          <div id="text_1">
                                            <a>{p.sku}</a>
                                          </div>
                                          <div id="text_1">
                                            <a>Quantity : 1</a>
                                          </div>
                                          <div id="text_1">
                                            {p.specialPrice > 0 ? (
                                              <React.Fragment>
                                                <a>
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    <s>
                                                      {UserService.numberFormat(
                                                        p.price
                                                      )}
                                                    </s>
                                                  </span>
                                                  &nbsp;&nbsp;
                                                  <span>
                                                    {UserService.numberFormat(
                                                      p.specialPrice
                                                    )}
                                                  </span>
                                                  <span className="vat">
                                                    (exc VAT)
                                                  </span>
                                                </a>
                                              </React.Fragment>
                                            ) : p.price > 0 ? (
                                              <React.Fragment>
                                                <span>
                                                  {UserService.numberFormat(
                                                    p.price
                                                  )}
                                                </span>
                                                <span className="vat">
                                                  (exc VAT)
                                                </span>
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <button
                                            type="button"
                                            className=" aacart-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center_1 .w-100 "
                                            onClick={() => addToAddonList(p)}
                                          >
                                            Adons
                                          </button>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* mandatory product group end */}

                      {/* optional product start */}
                      <div className="col-xl-6">
                        <div className="widget">
                          <div className="sidebar-title">
                            <div className="cart-option mb-15">
                              <a>
                                <span
                                  className="choose-rp"
                                  style={{ padding: "5px 5px" }}
                                >
                                  Optional
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="n-sidebar-feed">
                            <ul>
                              {opProducts.map((el) => {
                                return (
                                  <li key={el.prodcutId}>
                                    <div className="feed-number">
                                      <a>
                                        <img src={el.defaultImage} alt="" />
                                      </a>
                                    </div>
                                    <div className="feed-content">
                                      <h4>
                                        <a>{el.prodcutName}</a>
                                      </h4>
                                      <div id="text_1">
                                        <a>{el.sku}</a>
                                      </div>
                                      <div id="text_1">
                                        <a>Quantity : 1</a>
                                      </div>
                                      <div id="text_1">
                                        {el.specialPrice > 0 ? (
                                          <React.Fragment>
                                            <a>
                                              <span style={{ color: "red" }}>
                                                <s>
                                                  {UserService.numberFormat(
                                                    el.price
                                                  )}
                                                </s>
                                              </span>
                                              &nbsp;&nbsp;
                                              <span>
                                                {UserService.numberFormat(
                                                  el.specialPrice
                                                )}
                                              </span>
                                              <span className="vat">
                                                (exc VAT)
                                              </span>
                                            </a>
                                          </React.Fragment>
                                        ) : el.price > 0 ? (
                                          <React.Fragment>
                                            <span>
                                              {UserService.numberFormat(
                                                el.price
                                              )}
                                            </span>
                                            <span className="vat">
                                              (exc VAT)
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        className=" aacart-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center_1 .w-100 "
                                        onClick={() => addToAddonList(el)}
                                      >
                                        Adons
                                      </button>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* optional product end */}

                      {/* optional product group start */}
                      {opGrpProducts.map((el, idx) => {
                        return (
                          <div key={idx} className="col-xl-6">
                            <div className="widget">
                              <div className="sidebar-title">
                                <h4> {el.groupName} </h4>
                                <p>
                                  {el.quantity} Products Required. <br />
                                  Muti Maping
                                  {el.product.length > 0
                                    ? el.product[0].multiMapping === true
                                      ? "Enable"
                                      : "Disable"
                                    : ""}
                                  .
                                </p>
                                <div className="cart-option mb-15">
                                  <a>
                                    <span
                                      className="choose-rp"
                                      style={{ padding: "5px 5px" }}
                                    >
                                      Optional
                                    </span>
                                  </a>
                                </div>
                              </div>
                              <div className="n-sidebar-feed">
                                <ul>
                                  {el.product.map((p) => {
                                    return (
                                      <li>
                                        <div className="feed-number">
                                          <a>
                                            <img src={p.defaultImage} alt="" />
                                          </a>
                                        </div>
                                        <div className="feed-content">
                                          <h4>
                                            <a>{p.prodcutName}</a>
                                          </h4>
                                          <div id="text_1">
                                            <a>{p.sku}</a>
                                          </div>
                                          <div id="text_1">
                                            <a>Quantity : 1</a>
                                          </div>
                                          <div id="text_1">
                                            {p.specialPrice > 0 ? (
                                              <React.Fragment>
                                                <a>
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    <s>
                                                      {UserService.numberFormat(
                                                        p.price
                                                      )}
                                                    </s>
                                                  </span>
                                                  &nbsp;&nbsp;
                                                  <span>
                                                    {UserService.numberFormat(
                                                      p.specialPrice
                                                    )}
                                                  </span>
                                                  <span className="vat">
                                                    (exc VAT)
                                                  </span>
                                                </a>
                                              </React.Fragment>
                                            ) : p.price > 0 ? (
                                              <React.Fragment>
                                                <span>
                                                  {UserService.numberFormat(
                                                    p.price
                                                  )}
                                                </span>
                                                <span className="vat">
                                                  (exc VAT)
                                                </span>
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <button
                                            type="button"
                                            className=" aacart-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center_1 .w-100 "
                                            onClick={() => addToAddonList(p)}
                                          >
                                            Adons
                                          </button>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
              {/* Associate Product end */}

              {/* product spec data start */}
              {assoItemAvailability ? (
                <div className="col-xl-6">
                  <div className="product__details-Specification">
                    <div className="tabp">
                      <button
                        className="tablinks active"
                        onClick={() => window["openCity"](window.event, "SPEC")}
                        id="defaultOpen"
                      >
                        Specification
                      </button>
                      <button
                        className="tablinks"
                        onClick={() => window["openCity"](window.event, "DESC")}
                      >
                        Description
                      </button>
                      <button
                        className="tablinks"
                        onClick={() => window["openCity"](window.event, "DOC")}
                      >
                        Document
                      </button>
                    </div>
                  </div>
                  <div
                    className="product__desc-info"
                    style={{ paddingTop: "0px" }}
                  >
                    <div
                      id="SPEC"
                      className="tabcontent"
                      style={{ display: "block" }}
                    >
                      {specs.map((item, index) => {
                        return (
                          <ul key={index}>
                            <h5 className="spec-main-cat">{item.category}</h5>
                            {item.specs.map((attr, aidx) => {
                              return (
                                <li key={aidx}>
                                  <h6
                                    className="spec-attr-name"
                                    style={{ width: "30%" }}
                                  >
                                    {attr.name}
                                  </h6>
                                  <span>:&nbsp;&nbsp;</span>
                                  <span style={{ width: "69%" }}>
                                    {attr.value[0]}
                                    {attr.unit != null ? attr.unit : ""}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        );
                      })}
                    </div>

                    <div id="DESC" className="tabcontent">
                      {productState.shortDesc != "" ? (
                        <p>{productState.shortDesc}</p>
                      ) : (
                        ""
                      )}
                      {(productState.longDesc != "") != 0 ? (
                        <p>{productState.longDesc}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="DOC" className="tabcontent">
                      {productState.documents != null
                        ? productState.documents.map((doc) => {
                            return (
                              <div key={doc.fileId}>
                                <a href={doc.url} target="_blank">
                                  <span
                                    className=""
                                    style={{ padding: "2px 10px" }}
                                  >
                                    {doc.name}
                                  </span>
                                  &nbsp;
                                </a>
                              </div>
                            );
                          })
                        : ""}
                      {/* <a href="#"><span className="doccumnets" style={{ "padding": "2px 10px" }}>JBL Head set</span> &nbsp; </a> <i className="fa fa-file-pdf"></i> <br/>  <br/>
                                      <a href="#"><span className="doccumnets"  style={{"padding": "2px 10px"}}>JBL Head set 123 </span>  &nbsp;</a> <i className="fa fa-file-pdf"></i> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-xl-12">
                  <div className="product__details-Specification">
                    <div className="tabp">
                      <button
                        className="tablinks active"
                        onClick={() => window["openCity"](window.event, "SPEC")}
                        id="defaultOpen"
                      >
                        Specification
                      </button>
                      <button
                        className="tablinks"
                        onClick={() => window["openCity"](window.event, "DESC")}
                      >
                        Description
                      </button>
                      <button
                        className="tablinks"
                        onClick={() => window["openCity"](window.event, "DOC")}
                      >
                        Document
                      </button>
                      <button
                        className="tablinks"
                        onClick={() => window["openCity"](window.event, "REV")}
                      >
                        Reviews
                      </button>
                    </div>
                  </div>
                  <div
                    className="product__desc-info"
                    style={{ paddingTop: "0px" }}
                  >
                    <div
                      id="SPEC"
                      className="tabcontent"
                      style={{ display: "block" }}
                    >
                      {specs.map((item, index) => {
                        return (
                          <ul key={index}>
                            <h5 className="spec-main-cat">{item.category}</h5>
                            {item.specs.map((attr, aidx) => {
                              return (
                                <li key={aidx}>
                                  <h6
                                    className="spec-attr-name"
                                    style={{ width: "30%" }}
                                  >
                                    {attr.name}
                                  </h6>
                                  <span>:&nbsp;&nbsp;</span>
                                  <span style={{ width: "69%" }}>
                                    {attr.value[0]}
                                    {attr.unit != null ? attr.unit : ""}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        );
                      })}
                    </div>

                    <div id="DESC" className="tabcontent">
                      {productState.shortDesc != "" ? (
                        <p>{productState.shortDesc}</p>
                      ) : (
                        ""
                      )}
                      {(productState.longDesc != "") != 0 ? (
                        <p>{productState.longDesc}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="DOC" className="tabcontent">
                      {productState.documents != null
                        ? productState.documents.map((doc) => {
                            return (
                              <div key={doc.fileId}>
                                <a href={doc.url} target="_blank">
                                  <span
                                    className=""
                                    style={{ padding: "2px 10px" }}
                                  >
                                    {doc.name}
                                  </span>
                                  &nbsp;
                                </a>
                              </div>
                            );
                          })
                        : ""}
                      {/* <a href="#"><span className="doccumnets" style={{ "padding": "2px 10px" }}>JBL Head set</span> &nbsp; </a> <i className="fa fa-file-pdf"></i> <br/>  <br/>
                                      <a href="#"><span className="doccumnets"  style={{"padding": "2px 10px"}}>JBL Head set 123 </span>  &nbsp;</a> <i className="fa fa-file-pdf"></i> */}
                    </div>
                    <div id="REV" className="tabcontent"></div>
                  </div>
                </div>
              )}

              {/* product spec data end */}
            </div>
          </div>
        </div>
        {/* product details des Component start */}
      </main>

      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
